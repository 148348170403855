.footerContainer {
    background-color: #181C64;
    height: 200px;
    margin-top: 0px;
}

.footerContent {
    display: grid;
    grid-template-columns: 3fr 4fr;
    margin: 0 auto;
    padding-top: 25px;
}

.footerContent p {
    color: white;
    font-size: 18px;
    padding-top: 50px;
    font-family: Arial, Helvetica, sans-serif;
}

.footerContent img {
    height: 150px;
    float: right;
    padding-right: 0px;
}

@media screen and (max-width: 450px) { 
    .footerContent p {
        color: white;
        font-size: 18px;
        padding-top: 40px;
        margin-right: 15px;
    }
}