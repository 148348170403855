@font-face {
    font-family: gotham;
    src: url('./gotham/GothamBook.ttf');
  }

.menuContainer {
    background-position: -0px -0px;
}

.menu {
    display: grid;
    grid-template-columns: 1fr 1fr;
    font-family: gotham;
    height: 125px;
    width: 80%;
    max-width: 1250px;
    min-width: 1075px;
    margin: auto;
}

.menuLogo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.menuLogoImg {
    height: 120px;
}

.desktop {
    height: 50px;
    margin-top: 10px;
    margin-left: 10px;
    background-color: rgb(255, 255, 255);
}

.mobileButtons {
    display: none;
}

.mobile {
    display: none;
}

.menuButtons {
    background-color: rgb(255, 255, 255);
    margin: auto;
    overflow: hidden;
    padding-top: 15px;
}

.menuButtonPadding {
    padding-bottom: 35px;
}

.menuButtons button {
    padding: 30px 20px;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 18px;
    transition: all 0.25s;
}

.menuButtons button:hover {
    background-color: rgba(255, 255, 255, 1);
    color: #2F4AA8;
    cursor: pointer;
}

.menuBottomBorder {
    width: 100%;
}

@media screen and (max-width: 450px) {

    .menuContainer {
        width: 100%;
    }

    .menuLogo {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 100%;
        height: 100px;
    }

    .menuLogo img {
        width: 80%;
        height: auto;
        margin-left: 0px;
    }

    .menuLogoImg {
        display: none;
    }

    .desktop {
        display: none;
    }

    .mobileButtons {
        display: block;
    }

    .mobile {
        display: block;
        padding-top: 10px;
        padding-bottom: 12px;
        border-bottom: 2px solid black;
    }

    .menu {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto auto;
        font-family: gotham;
        height: auto;
        width: 100%;
        max-width: none;
        min-width: 350px;
        margin: auto;
        margin-bottom: 30px;
    }

    .menuButtonPadding {
        padding-bottom: 20px;
        text-align: center;
    }

    .menuButtonsContainer {
        width: 100%;
    }

    .menuButtons {
        background-color: rgb(255, 255, 255);
        /* margin: 0 auto; */
        text-align: center;
        overflow: hidden;
        padding: 0 auto;
        width: 80%;
        border-bottom: 2px solid black;
    }
    
    .menuButtons button {
        padding: 10px 25px;
        background-color: transparent;
        border: none;
        outline: none;
        font-size: 18px;
        transition: all 0.25s;
        height: 70px;
    }
  }