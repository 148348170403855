.header {
    /* height: 15px;
    background-color: rgb(0, 0, 0); */
}

.headerContent {
    width: 1250px;
    margin: auto;
    /* padding-top: 3px; */
}

.headerContent span {
    color: aliceblue;
    padding-left: 20px;
    font-size: 14px;
}